import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { database, auth, storage, reference } from "../../Config/firebase";
import {
  onValue,
  ref,
  remove,
  set,
  get,
  serverTimestamp,
  update,
} from "firebase/database";
import { deleteObject, getDownloadURL, uploadBytes } from "firebase/storage";

const removeQuizeFromDataBase = (uid) => {
  return new Promise((resolve, reject) => {
    get(ref(database, `userData/`))
      .then((e) => {
        // first loop
        for (const key in e.val()) {
          if (Object.hasOwnProperty.call(e.val(), key)) {
            const element = e.val()[key];

            // second loop
            for (const value in element) {
              if (Object.hasOwnProperty.call(element, value)) {
                const ele = element[value];

                // third loop
                for (const id in ele.resultArray) {
                  if (Object.hasOwnProperty.call(ele.resultArray, id)) {
                    const userId = +id;
                    const refer =
                      "userData/" +
                      key +
                      "/" +
                      value +
                      "/resultArray/" +
                      userId;

                    if (userId === uid) {
                      remove(ref(database, refer))
                        .then(() => {
                          console.log(true);
                        })
                        .catch((e) => {
                          reject({ message: e });
                        });
                    }
                  }
                }
              }
            }
          }
        }
      })
      .catch((err) => {
        reject({ message: err });
      });

    remove(ref(database, `ramzanQuizes/${uid}`))
      .then(() => {
        resolve({ message: `Quiz: ${uid} removed from ramzanQuizes` });
      })
      .catch((e) => {
        reject({ message: e });
      });
  });
};

const toogleOnQuizEnableDisable = (uid, name, flag, users) => {
  return new Promise((resolve, reject) => {
    if (users) {
      set(ref(database, `${name}/${uid}/enable`), {
        status: flag,
        users: users,
      })
        .then(() => {
          resolve("Changes Apply");
        })
        .catch(function (error) {
          reject({ error });
        });
    } else {
      set(ref(database, `${name}/${uid}/enable`), { status: flag, users: {} })
        .then(() => {
          resolve("Changes Apply");
        })
        .catch(function (error) {
          reject({ error });
        });
    }
  });
};

const removeDeathNewsAndEventNews = (uid, string, imageID) => {
  return new Promise((resolve, reject) => {
    console.log(uid, string, imageID);
    if (imageID === "") {
      remove(ref(database, `${string}/${uid}`))
        .then(() => {
          resolve({ message: `${string} removed` });
        })
        .catch((e) => {
          reject({ message: e });
        });
    } else {
      deleteObject(reference(storage, `${string}/${imageID || uid}`))
        .then(() => {
          remove(ref(database, `${string}/${uid}`)).then(() => {
            resolve({ message: `${string} removed` });
          });
        })
        .catch((e) => {
          reject({ message: e });
        });
    }
  });
};

const removeAboutUsAndDonateUs = (string) => {
  return new Promise((resolve, reject) => {
    remove(ref(database, string))
      .then(() => {
        resolve(true);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const removePopUpAndBannerImage = (string) => {
  return new Promise((resolve, reject) => {
    deleteObject(reference(storage, string))
      .then(() => {
        remove(ref(database, string)).then(() => {
          resolve({ message: `${string} removed` });
        });
      })
      .catch((e) => {
        reject({ message: e });
      });
  });
};

const removeBannerImage = (timeStamp) => {
  return new Promise((resolve, reject) => {
    deleteObject(reference(storage, `mainPageBanners/${timeStamp}`))
      .then(() => {
        console.log("rcbrev");
        remove(ref(database, `mainPageBanners/${timeStamp}`)).then(() => {
          resolve({ message: `Banner removed` });
        });
      })
      .catch((e) => {
        reject({ message: e });
      });
  });
};

const uploadQuiz = (obj) => {
  return new Promise((resolve, reject) => {
    set(ref(database, "timeStamp"), serverTimestamp())
      .then(() => {
        get(ref(database, "timeStamp"))
          .then((e) => {
            obj.timeStamp = e.val();
            console.log(obj.timeStamp);
            set(ref(database, `ramzanQuizes/${obj.timeStamp}`), obj).then(
              (e) => {
                resolve({ message: "Quiz Uploaded" });
              }
            );
          })
          .catch((e) => reject({ message: e }));
      })
      .catch((e) => reject({ message: e }));
  });
};

const uploadPopUpScreenImage = (files, obj) => {
  return new Promise((resolve, reject) => {
    uploadBytes(reference(storage, `popupScreen/`), files[0].blobFile)
      .then((e) => {
        getDownloadURL(reference(storage, e.ref._location.path_)).then((e) => {
          obj.ImageUrl = e;
          obj.name = files[0].blobFile.name;
          obj.type = files[0].blobFile.type;
          set(ref(database, "timeStamp"), serverTimestamp()).then(() => {
            get(ref(database, "timeStamp")).then((e) => {
              obj.timeStamp = e.val();
              set(ref(database, `popupScreen/`), obj).then(() => {
                set(
                  ref(database, "popupScreenLastUpdate/"),
                  obj.timeStamp.toString()
                ).then(() => {
                  resolve(true);
                });
              });
            });
          });
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const uploadMainPageBanner = (files, obj) => {
  return new Promise((resolve, reject) => {
    set(ref(database, "timeStamp"), serverTimestamp())
      .then(() => {
        get(ref(database, "timeStamp")).then((e) => {
          obj.timeStamp = e.val();
          uploadBytes(
            reference(storage, `mainPageBanners/${obj.timeStamp}`),
            files[0].blobFile
          ).then((e) => {
            getDownloadURL(reference(storage, e.ref._location.path_)).then(
              (e) => {
                obj.ImageUrl = e;
                obj.name = files[0].blobFile.name;
                obj.type = files[0].blobFile.type;
                set(
                  ref(database, `mainPageBanners/${obj.timeStamp}`),
                  obj
                ).then(() => {
                  set(
                    ref(database, "mainPageBannerLastUpdate/"),
                    obj.timeStamp.toString()
                  ).then(() => {
                    resolve(true);
                  });
                });
              }
            );
          });
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const eventsNewsAddFunc = (obj, files) => {
  return new Promise((resolve, reject) => {
    set(ref(database, "timeStamp"), serverTimestamp())
      .then(() => {
        get(ref(database, "timeStamp")).then((e) => {
          obj.timeStamp = e.val();
          if (files.length) {
            uploadBytes(
              reference(
                storage,
                `eventNews/${obj.timeStamp}${files[0].blobFile.name}`
              ),
              files[0].blobFile
            ).then((e) => {
              getDownloadURL(reference(storage, e.ref._location.path_))
                .then((event) => {
                  obj.imageUrls = [
                    {
                      url: event,
                      id: obj.timeStamp + files[0].blobFile.name,
                      type: files[0].blobFile.type,
                    },
                  ];
                })
                .then(() => {
                  set(ref(database, `eventNews/${obj.timeStamp}`), obj).then(
                    () => {
                      resolve({
                        imageUrl: obj.imageUrls[0].url,
                        getImage: true,
                        timeStamp: obj.timeStamp,
                      });
                    }
                  );
                });
            });
          } else {
            set(ref(database, `eventNews/${obj.timeStamp}`), obj).then(() => {
              resolve({
                imageUrl: "",
                getImage: false,
                timeStamp: obj.timeStamp,
              });
            });
          }
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const donateUsAddFunc = (obj) => {
  return new Promise((resolve, reject) => {
    set(ref(database, "timeStamp"), serverTimestamp())
      .then(() => {
        get(ref(database, "timeStamp")).then((e) => {
          obj.timeStamp = e.val();
          update(ref(database, "donateUs/"), obj).then(() => {
            resolve(true);
          });
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const deathNewsEmailListID = (key) => {
  return new Promise((resolve, reject) => {
    onValue(
      ref(database, "/emailListIds/deathNews"),
      (snapshot) => {
        resolve({
          message: "success",
          data: snapshot.val(),
        });
      },
      (errorObject) => {
        reject({ message: "error", data: errorObject });
      }
    );
  });
};

const deathNewsAddFunc = (obj, image) => {
  return new Promise((resolve, reject) => {
    set(ref(database, "timeStamp"), serverTimestamp())
      .then(() => {
        get(ref(database, "timeStamp")).then((e) => {
          obj.timeStamp = e.val();

          if (image.length) {
            uploadBytes(
              reference(
                storage,
                `deathNews/${obj.timeStamp}${image[0].blobFile.name}`
              ),
              image[0].blobFile
            )
              .then((e) => {
                getDownloadURL(reference(storage, e.ref._location.path_)).then(
                  (event) => {
                    obj.imageUrl = event;
                    obj.imageID = obj.timeStamp + image[0].blobFile.name;
                    obj.imageName = image[0].blobFile.name;
                    obj.imageType = image[0].blobFile.type;
                    set(ref(database, "deathNews/" + obj.timeStamp), obj).then(
                      () => {
                        set(
                          ref(database, "deathNewsLastUpdate/"),
                          obj.timeStamp
                        ).then(() => {
                          resolve({
                            imageUrl: obj.imageUrl,
                            getImage: true,
                            timeStamp: obj.timeStamp,
                          });
                        });
                      }
                    );
                  }
                );
              })
              .catch((e) => {
                reject({ message: e });
              });
          } else {
            set(ref(database, "deathNews/" + obj.timeStamp), obj)
              .then(() => {
                set(
                  ref(database, "deathNewsLastUpdate/"),
                  obj.timeStamp.toString()
                ).then(() => {
                  resolve({ getImage: false, timeStamp: obj.timeStamp });
                });
              })
              .catch((e) => {
                reject({ message: e });
              });
          }
        });
      })
      .catch((e) => {
        reject({ message: e });
      });
  });
};

const aboutUsAddFunc = (text, createdBy) => {
  return new Promise((resolve, reject) => {
    let obj = {
      text,
      createdBy,
    };
    set(ref(database, "timeStamp"), serverTimestamp())
      .then(() => {
        get(ref(database, "timeStamp")).then((e) => {
          obj = { ...obj, timeStamp: e.val() };
          set(ref(database, "aboutUs"), obj).then(() => {
            resolve(true);
          });
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const login = (email, password) => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((e) => {
        let uid = e.user.uid;
        set(ref(database, `adminUsers/${uid}/isLogin`), true).then((e) => {
          resolve(uid);
        });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

const signUp = (obj, password) => {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(auth, obj.userInfo.email, password)
      .then((e) => {
        obj.userInfo.uid = e.user.uid;
        set(ref(database, `adminUsers/${obj.userInfo.uid}`), obj).then(() => {
          resolve("User Created");
        });
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
const ControllingUser = (way, change) => {
  return new Promise((resolve, reject) => {
    set(ref(database, `adminUsers/${way}`), change)
      .then(() => {
        resolve("Changes Apply");
      })
      .catch(function (error) {
        reject({ error });
      });
  });
};
const ControllingFlags = (name, flag) => {
  return new Promise((resolve, reject) => {
    set(ref(database, `flags/${name}`), flag)
      .then(() => {
        resolve("Changes Apply");
      })
      .catch(function (error) {
        reject({ error });
      });
  });
};

const ControllingExtraBtns = (name, change, value) => {
  return new Promise((resolve, reject) => {
    set(
      ref(
        database,
        change ? `extraBtns/${name}/${change}` : `extraBtns/${name}`
      ),
      value
    )
      .then(() => {
        resolve("Changes Apply");
      })
      .catch(function (error) {
        reject({ error });
      });
  });
};

// Global Functions

const uploadData = (obj, path) => {
  return new Promise((resolve, reject) => {
    set(ref(database, path), obj)
      .then(() => {
        resolve({ status: "Success" });
      })
      .catch((e) => {
        reject({ status: "Error", error: e });
      });
  });
};

const uploadImage = (path, file) => {
  return new Promise((resolve, reject) => {
    uploadBytes(reference(storage, path), file)
      .then((e) => {
        getDownloadURL(reference(storage, e.ref._location.path_))
          .then((event) => {
            resolve(event);
          })
          .catch((err) => {
            console.log("getDownloadURL Error: " + err);
            reject("getDownloadURL Error: " + err);
          });
      })
      .catch((err) => {
        console.log("Uploading Image Error: " + err);
        reject("Uploading Image Error: " + err);
      });
  });
};

const getWebKey = async () => {
  return new Promise((resolve, reject) => {
    get(ref(database, "webKey"))
      .then((snapshot) => resolve(snapshot.val()))
      .catch((error) => reject(error));
  });
};

export {
  login,
  signUp,
  aboutUsAddFunc,
  deathNewsEmailListID,
  deathNewsAddFunc,
  donateUsAddFunc,
  eventsNewsAddFunc,
  uploadMainPageBanner,
  uploadPopUpScreenImage,
  uploadQuiz,
  removePopUpAndBannerImage,
  removeAboutUsAndDonateUs,
  removeDeathNewsAndEventNews,
  removeBannerImage,
  ControllingUser,
  ControllingFlags,
  ControllingExtraBtns,
  removeQuizeFromDataBase,
  toogleOnQuizEnableDisable,
  uploadData,
  uploadImage,
  getWebKey,
};
