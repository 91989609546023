import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DataTable from "../DataTable";
import { Loader } from "../../Helpers";

const QuizesResults = ({ quizUsersAndResults, quizes, users }) => {
  const [phoneAuthUsers, setPhoneAuthUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  // const ramzan = [
  //   12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
  //   31, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  // ];

  useEffect(() => {
    for (const key1 in users) {
      for (const key2 in users[key1]) {
        phoneAuthUsers.push(users[key1][key2]);
      }
    }
    setPhoneAuthUsers(phoneAuthUsers);
    setLoading(false);
  }, []);

  let authenticatedNumbers = [];
  let allUsers = [];
  let usersWhoAttempQuiz = [];
  for (let key in quizUsersAndResults) {
    authenticatedNumbers.push(quizUsersAndResults[key]);
    for (let key2 in quizUsersAndResults[key]) {
      allUsers.push(quizUsersAndResults[key][key2]);
      if (quizUsersAndResults[key][key2].resultArray) {
        if (quizUsersAndResults[key][key2].perFormUid !== undefined) {
          usersWhoAttempQuiz.push(quizUsersAndResults[key][key2]);
        } else {
          usersWhoAttempQuiz.push({
            ...quizUsersAndResults[key][key2],
            userId: key2,
          });
        }
      }
    }
  }
  let allQuizes = [];
  for (let key in quizes) {
    if (quizes[key].title !== "آزمائشی کوئز") {
      allQuizes.push(quizes[key]);
    }
  }

  function renderAge(param) {
    let selectedYear = new Date(param).getFullYear();
    let currentYear = new Date().getFullYear();
    return `${currentYear - selectedYear}`;
  }

  function showResultInSummery(object, res) {
    if (res[object.timeStamp]) {
      let answersArr = res[object.timeStamp].answersArray;
      let selectedValues = [];
      let correctAnswers = [];
      let wrongAnswers = [];
      for (let key in answersArr) {
        let nmbrOfCorrectAnswers = 0;
        let number = 0;
        selectedValues.push(answersArr[key].selectedAnswer);
        if (answersArr[key].isMultipleAnswers) {
          for (let key2 in object.questionsArray) {
            if (
              object.questionsArray[key2].question === answersArr[key].question
            )
              for (let key3 in object.questionsArray[key2]) {
                if (object.questionsArray[key2][key3].isTrue) {
                  nmbrOfCorrectAnswers = nmbrOfCorrectAnswers + 1;
                }
              }
          }
          for (let key2 in answersArr[key].selectedAnswer) {
            if (answersArr[key].selectedAnswer[key2].isTrue) {
              number = number + 1;
            }
          }
          if (number === nmbrOfCorrectAnswers) {
            correctAnswers.push(answersArr[key]);
          } else {
            wrongAnswers.push(answersArr[key]);
          }
        } else {
          if (answersArr[key].selectedAnswer[0].isTrue) {
            correctAnswers.push(answersArr[key]);
          } else {
            wrongAnswers.push(answersArr[key]);
          }
        }
      }
      return `${correctAnswers.length} out of ${answersArr.length}`;
    }
  }

  function renderResult(results, f) {
    if (Object.keys(results).indexOf(f.timeStamp.toString()) > -1) {
      return showResultInSummery(f, results);
    } else {
      return "N/A";
    }
  }

  const getQuizHeadings = () => {
    let names = [];
    allQuizes.map((ele, ind) => names.push(`Day ${ind + 1}\n${ele.title}`));
    return names;
  };

  const findPhoneUserData = (userId) => {
    return phoneAuthUsers.filter((ele) => ele.perFormUid === +userId);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="datatableWrapper">
      <p>Total Number Of Accounts Logged In : {authenticatedNumbers.length}</p>
      <p>Total Number Of Users : {allUsers.length}</p>
      <p>
        Total Number Of Users Who attempt quiz : {usersWhoAttempQuiz.length}
      </p>
      <DataTable
        headCells={[
          "S.NO",
          "Email Address",
          "User ID",
          "Date Time",
          // "Ramzan Roza",
          "CNIC #",
          "Phone Number",
          "Name",
          "Father / Husband Name",
          "Gender",
          "Country",
          "Age",
          ...getQuizHeadings(),
        ]}
      >
        {usersWhoAttempQuiz.map((e, i) => {
          if (e.perFormUid !== undefined) {
            let userInfo = e?.mainUserInfo;
            let condition = userInfo.uid === undefined;
            let emailOrUid = condition
              ? userInfo.email === undefined
                ? userInfo.emailAddress
                : userInfo.email
              : userInfo.uid;

            return (
              <tr
                key={i}
                style={{
                  backgroundColor: condition ? "" : "#FF7F7F",
                  color: condition ? "" : "white",
                }}
              >
                <td>{i + 1}</td>
                <td>{emailOrUid}</td>
                <td>{e.perFormUid}</td>
                <td>{new Date(+e.perFormUid).toLocaleString()}</td>
                <td>{e.cnicNo && e.cnicNo}</td>
                <td>
                  {e.phoneNoWithCode === undefined
                    ? userInfo.phoneNumber
                    : e.phoneNoWithCode}
                </td>
                <td className="username">{e.fullName}</td>
                <td className="username">{e.fatherName}</td>
                <td>{e.gender}</td>
                <td>{e.countryName}</td>
                <td>{renderAge(e.dateOfBirth)}</td>
                {allQuizes.map((f, l) => {
                  return (
                    <td key={l} style={{ textAlign: "center" }}>
                      {renderResult(e.resultArray, f)}
                    </td>
                  );
                })}
              </tr>
            );
          } else {
            const data = findPhoneUserData(e.userId);
            if (data[0] !== undefined) {
              const {
                mainUserInfo,
                gender,
                fullName,
                fatherName,
                dateOfBirth,
                countryName,
              } = data[0];
              const { phoneNumber, uid } = mainUserInfo;

              const d = new Date(+e.userId);
              const date = d.toLocaleString();
              // const ramzanDate = d.getDay();

              return (
                <tr
                  key={i}
                  style={{
                    backgroundColor: "#FF7F7F",
                    color: "white",
                  }}
                >
                  <td>{i + 1}</td>
                  <td>{uid}</td>
                  <td>{e.userId}</td>
                  {/* <td>{ramzan[ramzanDate]} Ramzan</td> */}
                  <td>{date}</td>
                  <td>--</td>
                  <td>{phoneNumber}</td>
                  <td className="username">{fullName}</td>
                  <td className="username">{fatherName}</td>
                  <td>{gender}</td>
                  <td>{countryName}</td>
                  <td>{renderAge(dateOfBirth)}</td>
                  {allQuizes.map((f, l) => {
                    return (
                      <td key={l} style={{ textAlign: "center" }}>
                        {renderResult(e.resultArray, f)}
                      </td>
                    );
                  })}
                </tr>
              );
            } else {
              return (
                <tr
                  key={i}
                  style={{
                    backgroundColor: "#FF7F7F",
                    color: "white",
                  }}
                >
                  <td>{i + 1}</td>
                  <td colSpan={8}>
                    Member Not Found &nbsp; | &nbsp; User ID: {e.userId}
                  </td>
                  {allQuizes.map((f, l) => {
                    return (
                      <td key={l} style={{ textAlign: "center" }}>
                        {renderResult(e.resultArray, f)}
                      </td>
                    );
                  })}
                </tr>
              );
            }
          }
        })}
      </DataTable>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    quizUsersAndResults: state.quizUsersAndResults,
    quizes: state.quizes,
    users: state.users,
  };
};

export default connect(mapStateToProps)(QuizesResults);
